/* eslint-disable camelcase, complexity */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import SocialIcon from "../../shared/icon-social";
import LiLink from "../../shared/li-llink";
import { footerVariation } from "../../../variations/footer";
import { sendCustomGaEvents } from "../../../tracking";
import { footerClick } from "./tracking";
import driver from "./images/driver.svg";
import LazyImage from "../../shared/lazy-image";
import { seoLinkClicked, downloadBannerClick, downloadBannerView } from "../../../constants/tracking-constants/super-home-events";
import { DOWNLOAD_BANNER_TYPES } from "../../../constants/super-app-constants";
import { InView } from "react-intersection-observer";
import { WHITE_LOGO_URL } from "../../../constants/app-constants";

const Footer = (props) => {
    const {
        data,
        history,
        route
    } = props;

    const {
        popular_search = {},
        social_media = {},
        useful_links = {},
        app_links = {},
        other_geographies = {}
    } = data || {};

    const filterUsefulLinks = footerVariation(history.location.pathname).filterUsefulLinks;
    const superHomeRoute = route && route.name === "buy-used-cars";

    const sendFooterEvent = (text, url) => {

        if (superHomeRoute) {
            sendCustomGaEvents({
                ...seoLinkClicked,
                eventLabel: url
            });
        } else {

            sendCustomGaEvents({
                ...footerClick,
                eventCategory: route.name
            });

        }
    };

    const links = (obj, key) => (
        <LiLink key={key} isUsedCarRoute={(route || {}).isUsedCarRoute} onClickCallback={
            ({ title, value, url }) => sendFooterEvent(title || value, url)} {...obj} />
    );

    const sendStoreEvent = (text) => {
        const textMap = {
            app_store: "Google Play",
            play_store: "App store"
        };
        if (superHomeRoute) {
            sendCustomGaEvents({
                ...downloadBannerClick,
                eventLabel: `${DOWNLOAD_BANNER_TYPES.FOOTER} | Desktop`
            });
        }
        sendCustomGaEvents({
            ...footerClick,
            eventCategory: route.name,
            eventLabel: textMap[text]
        });
    };

    const onViewHandler = (inView) => {
        if (inView) {
            sendCustomGaEvents({
                ...downloadBannerView,
                eventLabel: `${DOWNLOAD_BANNER_TYPES.FOOTER} | Desktop`
            });
        }
    };

    const getCategoryName = (title = "") => {
        return (title || "").replace(/(^\w{1})/g, letter => letter.toUpperCase());
    };

    return (
        <div>
            <footer styleName={"styles.footer"}>
                <div className={"customContainer"} >
                    <LazyImage src={WHITE_LOGO_URL} alt="cars24-logo" width={124} height={55}/>
                    <div styleName={"styles.footerRow"}>

                        <div styleName={"styles.leftColumn"}>
                            <h3 styleName={"styles.heading"}>{getCategoryName((useful_links.title || "").toLowerCase())}</h3>
                            <ul styleName={"styles.footerLink"}>
                                {filterUsefulLinks(useful_links.links || []).map(links)}
                            </ul>
                        </div>

                        <div styleName={"styles.rightColumn"}>
                            <div styleName={"styles.linkRow"}>
                                <div>
                                    <h3 styleName={"styles.heading"}>{getCategoryName((popular_search.title || "").toLowerCase())}</h3>
                                    <ul styleName={"styles.footerLink"}>
                                        {
                                            (popular_search.links || []).map(popular => (
                                                <li
                                                    onClick={() => sendFooterEvent(popular.title, popular.url)}
                                                    key={`popular-search-${popular.title}`}>
                                                    <a href={popular.url} target={popular.target}>{popular.title}</a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                <div>
                                    <h3 styleName={"styles.heading"}>{getCategoryName((other_geographies.title || "").toLowerCase())}</h3>
                                    <ul styleName={"styles.footerLink"}>
                                        {
                                            (other_geographies.links || []).map(geo => (
                                                <li
                                                    onClick={() => sendFooterEvent(geo.title, geo.url)}
                                                    key={`geo-city-${geo.title}`}>
                                                    <a href={geo.url} target={geo.target}>{geo.title}</a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                <div>
                                    <h3 styleName={"styles.heading"}>{getCategoryName((social_media.title || "").toLowerCase())}</h3>
                                    <ul styleName={"styles.socialMedia"}>
                                        {
                                            (social_media.links || []).map((social, idx) => (
                                                <li key={`social-media-${idx}`}>
                                                    <a
                                                        onClick={() => sendFooterEvent(social.name)}
                                                        href={social.url}
                                                        target={social.target || ""}
                                                        aria-label={social.title}
                                                    >
                                                        <SocialIcon
                                                            name={social.title}
                                                            imageUrl={social.image_url}
                                                        />
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <InView threshold={1} onChange={(inView) => onViewHandler(inView)}>
                                        <div styleName={"styles.appDownload"}>
                                            {(app_links.links || []).map((link, index) => {
                                                return (<a
                                                    key={index}
                                                    onClick={() => sendStoreEvent(link.title)}
                                                    href={link.url}
                                                    aria-label={link.title}>
                                                    <SocialIcon
                                                        name={link.title} imageUrl={link.image_url}
                                                    />
                                                </a>);
                                            })}
                                        </div>
                                    </InView>
                                </div>
                            </div>

                            <div styleName={"styles.driving"}>
                                <div>
                                    <h4>Driving happier car <br/> experiences </h4>
                                    <p>Your key to smoother rides starts here 😍</p>
                                </div>
                                <div styleName={"styles.driverIcon"}>
                                    <LazyImage src={driver} alt="Driving happier car "/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

Footer.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    history: PropTypes.object,
    route: PropTypes.object
};

export default Footer;
