import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "./component";
import { withRouter } from "react-router";
import { locationPageType, setIsCTAClickedWithoutPincode, toggleLocationPicker } from "../location-picker-popup/actions";
import { overrideSpecifiedAppliedFilter, updateCarListOptions, updateCurrentPage, fetchCityListAndBrands, updatePopularCityListSSRStatus } from "../car-list/actions";
import { fetchPresetFilters } from "./action";
import { setIsIndiaPage } from "../../filters/actions";
import withOtp from "../../shared/with-otp";
import { updateHomePageSSRStatus } from "../buy-used-car/actions";
//import { updateSSRStatus } from "../footer/actions";

const mapStateToProps = ({
    cities: { selectedCity },
    carList: {
        filters = {},
        isPopularCityListSSR
    } = {},
    user: {
        name,
        isLoggedIn,
        isFetched,
        mobile,
        email,
        userId
    },
    config: {
        cityList,
        activeHeaderTab
    },
    locationPicker: { isCTAClickedWithoutPincode },
    filters: {
        isIndiaPage
    },
    buyUsedCarHome: {
        curatedPicks,
        isSSR: buyUsedSSR
    },
    wishlist: {
        wishlistCount
    },
    location: {pincode, cityInfo: { city_name: cityName, city_id: cityId } },
    header: {headerVisibility},
    nearbyCities: {  isServiceable, isNearByCityAvailable },
    locationPicker: {showLocationPicker}

}) => ({
    selectedCity,
    pincode,
    filters,
    userName: name,
    isLoggedIn,
    isFetched,
    cityList,
    activeHeaderTab,
    isCTAClickedWithoutPincode,
    isIndiaPage,
    isPopularCityListSSR,
    mobile,
    email,
    curatedPicks,
    buyUsedSSR,
    wishlistCount,
    cityName,
    cityId,
    userId,
    headerVisibility,
    isServiceable,
    isNearByCityAvailable,
    showLocationPicker
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    toggleLocationPickerConnect: toggleLocationPicker,
    overrideSpecifiedAppliedFilterConnect: overrideSpecifiedAppliedFilter,
    locationPageTypeConnect: locationPageType,
    updateCurrentPageConnect: updateCurrentPage,
    setIsIndiaPageConnect: setIsIndiaPage,
    setIsCTAClickedWithoutPincodeConnect: setIsCTAClickedWithoutPincode,
    updateCarListOptionsConnect: updateCarListOptions,
    fetchCityListAndBrandsConnect: fetchCityListAndBrands,
    updatePopularCityListSSRStatusConnect: updatePopularCityListSSRStatus,
    fetchPresetFiltersConnect: fetchPresetFilters,
    updateHomePageSSRStatusConnect: updateHomePageSSRStatus
    //updateSSRStatusConnect: updateSSRStatus
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withOtp(Header)));
