import Types from "./types";

import { CatalogGatewayService } from "../../../service/catalog-gateway";
import { fetchPresetFiltersSuccess } from "../buy-used-car/actions";

const fetchPresetFilters = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getPresetFilters(params)
            .then(response => {
                dispatch(fetchPresetFiltersSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const setHeaderVisibility = (headerVisibility) => ({
    type: Types.SET_HEADER_VISIBILITY,
    headerVisibility
});

export {
    fetchPresetFilters,
    setHeaderVisibility
};
