import React from "react";
import { Helmet } from "react-helmet-async";
import { appUrl, getLogoURL } from "../../../constants/url-constants";

const MetaTagsSuperWebHome = () => {
    const baseUrl = `${appUrl()}/`;
    const metaTitle = "CARS24: Buy & Sell Used Cars | Car Loan | New Car | Challan";
    const metaDescription = "Buy & sell used cars, get car loans, check new car info with CARS24. Explore services like paying challan, car scrapping, maintenance, driver hire, and more.";
    const metaKeywords = "Buy used cars, buy used cars online, buy used cars online in India, Used Cars Online, Used Cars Online in India, Second Hand Cars, Second Hand Cars Online, Second Hand Cars Online in India, Buy Second hand cars online in India, Certified Used Cars, Pre-owned cars online in India";

    const pageSchema = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "CARS24",
        "url": "https://www.cars24.com",
        "logo": "https://fastly-production.24c.in/cars24/seo/static/1_20230830_1693395013.png",
        "description": `${metaDescription}`,
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "tel:1800 258 5656",
            "email": "care@cars24.com",
            "contactType": "customer service",
            "areaServed": "IN"
        },
        "sameAs": [
            "https://fastly-production.24c.in/cars24/seo/static/1_20230830_1693395013.png",
            "https://twitter.com/cars24india/",
            "https://www.youtube.com/channel/UCV-CPspQhwTsaiftR4JvT0w/",
            "https://www.instagram.com/cars24india/",
            "https://in.linkedin.com/company/cars24/",
            "https://en.wikipedia.org/wiki/CARS24"
        ]

    });

    return (
        <Helmet script={[{
            type: "application/ld+json",
            innerHTML: pageSchema
        }]}>
            <title>{metaTitle}</title>
            <meta name="title" content={metaTitle} />
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={`${baseUrl}`} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Cars24" />
            <meta property="og:image" content={getLogoURL()} />
            <meta name="ROBOTS" content="INDEX,FOLLOW" />
            <link href={`${baseUrl}`} rel="canonical" />
            <link rel="alternate" href="https://www.cars24.com/" hrefLang="x-default" />
            <link rel="alternate" href="https://www.cars24.com/" hrefLang="en-IN" />
            <link rel="alternate" href="https://www.cars24.com.au/" hrefLang="en-AU" />
            <link rel="alternate" href="https://www.cars24.ae/" hrefLang="en-AE" />
        </Helmet>
    );
};

MetaTagsSuperWebHome.propTypes = {};

export default MetaTagsSuperWebHome;
