/* eslint-disable no-undef */
/* eslint-disable no-var */
import React, { useState, useRef} from  "react";
import PropTypes from "prop-types";
import { NUMBER } from "../../../constants/app-constants";
import styles from "./styles.css";

const Swiper = ({
    children,
    onChange = () => { },
    hideArrow = false
}) => {

    const [nextArrowDisabled, setNextArrowDisabled] = useState(false);
    const [prevArrowDisabled, setPrevArrowDisabled] = useState(true);
    const sliderRef = useRef(null);
    const callBackRef = useRef(false);

    const scroll = (scrollOffset) => {
        if (sliderRef && sliderRef.current) {
            let scrollAmount = 0;
            var slideTimer = setInterval(() => {
                sliderRef.current.scrollBy({
                    top: 0,
                    left: scrollOffset,
                    behavior: "smooth"
                });

                scrollAmount += NUMBER.TEN;
                if (scrollAmount >= NUMBER.FIFTEEN) {
                    window.clearInterval(slideTimer);
                }
            }, NUMBER.FIVE);

            setPrevArrowDisabled(!(sliderRef.current.scrollLeft));
            setNextArrowDisabled(sliderRef.current.scrollLeft >= (sliderRef.current.scrollWidth - sliderRef.current.offsetWidth));
        }
    };

    const onHorizontalScroll = () => {
        if (!callBackRef.current) {
            onChange();
            callBackRef.current = true;
        }
        setPrevArrowDisabled(!(sliderRef.current.scrollLeft));
        setNextArrowDisabled(sliderRef.current.scrollLeft >= (sliderRef.current.scrollWidth - sliderRef.current.offsetWidth));
    };

    return (
        <React.Fragment>
            <div styleName={"styles.sliderContainer"} >
                <button onClick={() => scroll(-NUMBER.FOUR_HUNDRED)} type="button" className="slick-prev" styleName={
                    prevArrowDisabled ? `${hideArrow ? "slickHidden" : "styles.slick-disabled"}` : "styles.prevNew"}>Previous</button>
                <div style={{ gap: `${NUMBER.TWENTY_FOUR}px` }} styleName={"styles.sliderOvrflow"} ref={sliderRef} onScroll={onHorizontalScroll}>
                    {children}
                </div>
                <button onClick={() => scroll(NUMBER.FOUR_HUNDRED)} type="button" className="slick-next" styleName={nextArrowDisabled ? "styles.slick-disabled" : "styles.nextNew"}>Next</button>
            </div>
        </React.Fragment>
    );
};

const SwiperSlider = ({ children }) => {
    return (
        <React.Fragment>
            <div className={"slideBox"} styleName={"styles.slideBox"}>
                {children}
            </div>
        </React.Fragment>

    );
};

Swiper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    options: PropTypes.object,
    onChange: PropTypes.func,
    hideArrow: PropTypes.bool
};
SwiperSlider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node])
};

export  {
    Swiper,
    SwiperSlider
};
