import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    IS_DATA_LOADING
    SET_IS_SSR
    SET_SUPER_APP_HOME_DATA_INIT
    SET_SUPER_APP_HOME_DATA_SUCCESS
    SET_SUPER_APP_HOME_DATA_FAILURE

    SET_BANNER_POPUP_CONTENT
`,
    {
        prefix: "superApp/"
    }
);
