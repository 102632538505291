import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { useEffect } from "react";

import { sendCustomGaEvents } from "../../../tracking";
import { trandingSearchesView, trandingSearchesOnClick } from "./tracking";

const TrendingSearchList = ({
    trendingSearch,
    handleTrendingSearchSelect,
    dimensionObject
}) => {

    useEffect(() => {
        if (trendingSearch && trendingSearch.length > 0) {
            const trendingSearchValues = (trendingSearch || []).map((i) => i.title).join("|");
            sendCustomGaEvents({
                ...trandingSearchesView,
                eventAction: trendingSearchValues,
                eventLabel: trendingSearch.length,
                ...dimensionObject
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trendingSearch]);

    const handleOnClick = (option, index) => {
        const { title } = option || {};
        handleTrendingSearchSelect(option);
        sendCustomGaEvents({
            ...trandingSearchesOnClick,
            eventAction: `${title} | ${index + 1}`,
            eventLabel: trendingSearch.length,
            ...dimensionObject
        });
    };

    return (
        <div styleName={"styles.searchListWrap"}>
            <h5 styleName={"styles.heading"}>Trending Cars</h5>
            <ul styleName={"styles.trendingSearch"}>
                {trendingSearch && trendingSearch.length > 0 && trendingSearch.map((option, index) => {
                    const { title } = option;
                    return (
                        <li key={index} onClick={() => handleOnClick(option, index)}>
                            {title}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
TrendingSearchList.propTypes = {
    dimensionObject: PropTypes.object,
    trendingSearch: PropTypes.array,
    handleTrendingSearchSelect: PropTypes.func
};

export default TrendingSearchList;
