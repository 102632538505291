import React from "react";

const ArrowTailed = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24" className="min-w-4 min-h-4 w-4 h-4">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7 17L17 7m0 0H7m10 0v10" />
        </svg>
    );
};

export default ArrowTailed;
