export const newCarsBodyTypeLink = [
    {
        title: "Sedan",
        seoLink: "new-cars/search-by/sedan-cars/"
    },
    {
        title: "SUV",
        seoLink: "new-cars/search-by/suv-cars/"
    },
    {
        title: "Hatchback",
        seoLink: "new-cars/search-by/hatchback-cars/"
    },
    {
        title: "MUV",
        seoLink: "new-cars/search-by/muv-cars/"
    }
];

export const newCarsBrandsLink = [
    {
        title: "Tata",
        seoLink: "new-cars/tata/"
    },
    {
        title: "Maruti Suzuki",
        seoLink: "new-cars/maruti-suzuki/"
    },
    {
        title: "Kia",
        seoLink: "new-cars/kia/"
    },
    {
        title: "Toyota",
        seoLink: "new-cars/toyota/"
    },
    {
        title: "MG",
        seoLink: "new-cars/mg/"
    },
    {
        title: "Honda",
        seoLink: "new-cars/honda/"
    }
];

export const newCarsPopularLinks = [
    {
        title: "Hyundai Creta",
        seoLink: "new-cars/hyundai/creta/"
    },
    {
        title: "Mahindra Thar",
        seoLink: "new-cars/mahindra/thar/"
    },
    {
        title: "Tata Nexon",
        seoLink: "new-cars/tata/nexon/"
    },
    {
        title: "Tata Punch",
        seoLink: "new-cars/tata/punch/"
    },
    {
        title: "Maruti Swift",
        seoLink: "new-cars/maruti-suzuki/swift/"
    },
    {
        title: "Kia Seltos",
        seoLink: "new-cars/kia/seltos/"
    }
];

export const newCarsPriceLink = [
    {
        title: "Under 5 Lakhs",
        seoLink: "new-cars/search-by/cars-under-5-lakhs/"
    },
    {
        title: "Under 10 Lakhs",
        seoLink: "new-cars/search-by/cars-under-10-lakhs/"
    },
    {
        title: "Under 15 Lakhs",
        seoLink: "new-cars/search-by/cars-under-15-lakhs/"
    },
    {
        title: "Under 20 Lakhs",
        seoLink: "new-cars/search-by/cars-under-20-lakhs/"
    }
];

export const newCarLinks = [
    {
        heading: "Popular Brands",
        links: newCarsBrandsLink
    },
    {
        heading: "Populare Cars",
        links: newCarsPopularLinks
    },
    {
        heading: "Car By Body Type",
        links: newCarsBodyTypeLink
    },
    {
        heading: "Cars By Price Range",
        links: newCarsPriceLink
    }
];
