import React from "react";
import styles from "./styles.css";
import ChatIcon from "./images/help-icon.svg";
import PropTypes from "prop-types";

const ChatWidget = ({
    onClick = () => {}
}) => {
    return (
        <div styleName={"styles.chatRevampContainer"} onClick={onClick}>
            <div styleName={"styles.chatContainer"}>
                <img src={ChatIcon} alt={"need help"}/>
                <p styleName={"styles.needHelpText"}>Need help?</p>
                <p styleName={"styles.helpText"}>Help</p>
            </div>
        </div>
    );
};

export default ChatWidget;

ChatWidget.propTypes = {
    onClick: PropTypes.func.isRequired
};
