export const SUPER_APP_PAGE_TYPES = {
    SUPER_WEB_HOME_PAGE: "SUPER_WEB_HOME_PAGE"
};

export const SUPER_APP_SECTION_TYPES = {
    SUPER_WEB_HOME_PAGE_HEADER: "SUPER_WEB_HOME_PAGE_HEADER",
    SUPER_WEB_SERVICES: "SUPER_WEB_SERVICES",
    SUPER_WEB_DISCOUNT_SECTION: "SUPER_WEB_DISCOUNT_SECTION",
    SUPER_WEB_GET_APP_SECTION: "SUPER_WEB_GET_APP_SECTION",
    SUPER_WEB_OUR_SERVICES_SECTION: "SUPER_WEB_OUR_SERVICES_SECTION",
    SUPER_WEB_WHY_BUY_US_SECTION: "SUPER_WEB_WHY_BUY_US_SECTION",
    SUPER_WEB_HAPPY_STORY_SECTION: "SUPER_WEB_HAPPY_STORY_SECTION",
    SUPER_WEB_NEWS_SECTION: "SUPER_WEB_NEWS_SECTION",
    SUPER_WEB_DOWNLOAD_APP_SECTION: "SUPER_WEB_DOWNLOAD_APP_SECTION",
    SUPER_WEB_APP_RATING_SECTION: "SUPER_WEB_APP_RATING_SECTION",
    SUPER_WEB_REVIEW_SECTION: "SUPER_WEB_REVIEW_SECTION",
    SUPER_WEB_NEED_HELP_SECTION: "SUPER_WEB_NEED_HELP_SECTION",
    SUPER_WEB_BUY_CAR_SECTION: "SUPER_WEB_BUY_CAR_SECTION",
    SUPER_WEB_CARS_BY_CATEGORY: "SUPER_WEB_CARS_BY_CATEGORY",
    SUPER_WEB_FOOTER_GRID: "SUPER_WEB_FOOTER_GRID"
};

export const SUPER_WEB_BANNER_TYPE = {
    SUPER_WEB_GET_APP_PAGE_BANNER: "SUPER_WEB_GET_APP_PAGE_BANNER",
    SUPER_WEB_GET_APP_NUDGE_BANNER: "SUPER_WEB_GET_APP_NUDGE_BANNER"
};

export const USER_PARSER_DEVICE_TYPES = {
    TAB: "tablet"
};

export const MEDIA_DEVICE_TYPES = {
    MSITE: "MSITE",
    DESKTOP: "DESKTOP",
    TAB: "TAB"
};

export const MEDIA_TYPES = {
    IMAGE: "IMAGE",
    ANIMATION: "ANIMATION",
    VIDEO: "VIDEO"
};

export const CUSTOM_FLOW_TYPES = {
    BUY: "BUY",
    BUY_FILTER: "BUY_FILTER",
    EMI: "EMI",
    EMI_DISCOUNT: "EMI_DISCOUNT",
    WHATSAPP: "WHATSAPP",
    CALLBACK: "CALLBACK",
    APP_NUDGE: "APP_NUDGE"
};

export const DEVICE_CONFIG = {
    "tablet": "TAB",
    "mobile": "MSITE"
};

export const downloadAppKeys = {
    SUPER_WEB_DOWNLOAD_QR_CODE: "SUPER_WEB_DOWNLOAD_QR_CODE",
    SUPER_WEB_DOWNLOAD_BACKGROUND: "SUPER_WEB_DOWNLOAD_BACKGROUND",
    SUPER_WEB_DOWNLOAD_APP_STORE: "SUPER_WEB_DOWNLOAD_APP_STORE",
    SUPER_WEB_DOWNLOAD_PLAY_STORE: "SUPER_WEB_DOWNLOAD_PLAY_STORE"
};

export const numberRegex = /^[6789]\d{9}$/;
export const digitRegex = /^\d+$/;

export const SERVICE_TAB_LABEL = {
    MORE: "More"
};

export const DOWNLOAD_ICON_TYPES = {
    IOS: "IOS",
    ANDROID: "ANDROID"
};

export const PLATFORM_TYPE = {
    [MEDIA_DEVICE_TYPES.DESKTOP]: "Desktop",
    [MEDIA_DEVICE_TYPES.MSITE]: "mSite",
    [MEDIA_DEVICE_TYPES.TAB]: "Tablet"
};

export const DOWNLOAD_BANNER_TYPES = {
    HEADER: "Header Banner",
    INLINE: "Inline Banner",
    OTP_LINK: "Otp link clicked",
    DOWNLOAD: "download app banner",
    MOTIVATE: "What motivate us",
    FOOTER: "Footer",
    BOTTOM_SHEET: "Bottomsheet"
};
