/* eslint-disable max-params */
import React, { useState, useEffect, Fragment } from "react";
import loadable from "@loadable/component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../footer";
import "../../../styles/reset.desktop.css";
import "../../../styles/slick.css";
import "../../../styles/slick-theme.css";
import "../../../styles/slick-reset-desktop.css";
import "@cars24/turbo-web/lib/index.css";
import Error from "../../shared/error";
import ErrorHandler from "../../shared/with-error-handler";
import "intersection-observer";
import ChatIcon from "../../desktop/chat-icon";
import SupportWidget from "../support-widget";
import { setModalSource } from "../help-support-modal/action";
const LocationPickerPopup = loadable(() => import("../../desktop/location-picker-popup"), { ssr: false });
const ComingSoonCityModal = loadable(() => import("../../desktop/coming-soon-city-modal"), { ssr: false });
const CfPopup = loadable(() => import("../../desktop/cf-popup"), { ssr: false });
const ToastMessage = loadable(() => import("../toast-message"), { ssr: false });

import HeaderV2 from "../header-v2/";

export default function withLayout(WrappedComponent, header = true, footer = true, locationPopup = true, isChatIcon = true) {

    const Layout = (props) => {
        const [wishlistToast, setWishlistToast] = useState(null);
        const [cfTenurePopupComp, setCfTenurePopupComp] = useState(null);

        useEffect(() => {
            if (props.addToWishlistCarId || props.removeFromWishlistCarId) {
                import("../wishlist-toast").then((comp) => {
                    setWishlistToast(<comp.default {...props} />);
                });
            }

            if (props.isTenurePopupVisible) {
                import("../cf-tenure-popup").then((comp) => {
                    setCfTenurePopupComp(<comp.default {...props}/>);
                });
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.addToWishlistCarId, props.removeFromWishlistCarId, props.isTenurePopupVisible]);

        const getLayout = () => {
            let component = (
                <Fragment>
                    {header && <HeaderV2 {...props} />}
                    <ErrorHandler>
                        {
                            wishlistToast && wishlistToast
                        }
                    </ErrorHandler>
                    <ErrorHandler>
                        <ToastMessage />
                    </ErrorHandler>
                    <ErrorHandler>
                        <WrappedComponent {...props} />
                    </ErrorHandler>
                    {footer && <Footer {...props} />}
                    <ErrorHandler>
                        {locationPopup && <LocationPickerPopup {...props} />}
                    </ErrorHandler>
                    {isChatIcon && <ChatIcon {...props} />}
                    <ErrorHandler>
                        <SupportWidget {...props}/>
                    </ErrorHandler>
                    <ErrorHandler>
                        <ComingSoonCityModal {...props} />
                    </ErrorHandler>
                    <ErrorHandler>
                        {props.isCfVisible && <CfPopup />}
                    </ErrorHandler>
                    <ErrorHandler>
                        {
                            (cfTenurePopupComp && props.isTenurePopupVisible) &&
                            cfTenurePopupComp
                        }
                    </ErrorHandler>
                    {/* <ErrorHandler>
                        <ChooseCountry />
                    </ErrorHandler> */}
                </Fragment>
            );
            if (props.isSSRError) {
                component = <Error />;
            }
            return component;
        };

        return getLayout();
    };
    const mapStateToProps = ({
        user: { isSSRError },
        wishlist: {
            addToWishlistCarId,
            removeFromWishlistCarId
        },
        cfPopup: {
            isVisible: isCfVisible
        },
        consumerFinance: {
            isTenurePopupVisible
        }
    }) => {
        return {
            isSSRError,
            isCfVisible,
            addToWishlistCarId,
            removeFromWishlistCarId,
            isTenurePopupVisible
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        setModalSourceConnect: setModalSource
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(Layout);
}
