import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SuperAppComponent from "./component";
import { withRouter } from "react-router-dom";
import { updateSortOrder, reloadCarList } from "../car-list/actions";
import { setIsSSR, fetchSuperAppHomeData, setBannerPopupContent } from "./actions";
import { locationPageType, toggleLocationPicker, setIsCTAClickedWithoutPincode } from "../location-picker-popup/actions";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        preApproved,
        gaId,
        statsigId,
        preApprovalScreenVariant
    },
    superApp: {
        dataList,
        isSSR,
        bannerContent,
        isLoading,
        parsedData
    },
    filters,
    cities,
    location: { pincode },
    consumerFinance: {
        cfStatus,
        selectedOffer
    },
    locationPicker: { isCTAClickedWithoutPincode }
}) => ({
    superAppDataList: dataList,
    isSSR,
    isLoading,
    filters,
    cities,
    pincode,
    isLoggedIn,
    preApproved,
    gaId,
    statsigId,
    preApprovalScreenVariant,
    cfStatus,
    selectedOffer,
    bannerContent,
    isCTAClickedWithoutPincode,
    parsedData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSortOrderConnect: updateSortOrder,
    reloadCarListConnect: reloadCarList,
    setIsSSRConnect: setIsSSR,
    fetchSuperAppHomeDataConnect: fetchSuperAppHomeData,
    setBannerPopupContentConnect: setBannerPopupContent,
    toggleLocationPickerConnect: toggleLocationPicker,
    locationPageTypeConnect: locationPageType,
    setIsCTAClickedWithoutPincodeConnect: setIsCTAClickedWithoutPincode
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuperAppComponent));
