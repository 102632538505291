export const buyCarLinks = [
    {
        title: "Delhi NCR",
        seoLink: "buy-used-cars-delhi-ncr/"
    },
    {
        title: "New Delhi",
        seoLink: "buy-used-cars-new-delhi/"
    },
    {
        title: "Mumbai",
        seoLink: "buy-used-cars-mumbai/"
    },
    {
        title: "Bangalore",
        seoLink: "buy-used-cars-bangalore/"
    },
    {
        title: "Hyderabad",
        seoLink: "buy-used-cars-hyderabad/"
    },
    {
        title: "Ahmedabad",
        seoLink: "buy-used-cars-ahmedabad/"
    },
    {
        title: "Gurgaon",
        seoLink: "buy-used-cars-gurgaon/"
    },
    {
        title: "Chennai",
        seoLink: "buy-used-cars-chennai/"
    },
    {
        title: "Pune",
        seoLink: "buy-used-cars-pune/"
    },
    {
        title: "Noida",
        seoLink: "buy-used-cars-noida/"
    },
    {
        title: "Ghaziabad",
        seoLink: "buy-used-cars-ghaziabad/"
    },
    {
        title: "Lucknow",
        seoLink: "buy-used-cars-lucknow/"
    },
    {
        title: "Jaipur",
        seoLink: "buy-used-cars-jaipur/"
    },
    {
        title: "Kolkata",
        seoLink: "buy-used-cars-kolkata/"
    },
    {
        title: "Kochi",
        seoLink: "buy-used-cars-kochi/"
    },
    {
        title: "Indore",
        seoLink: "buy-used-cars-indore/"
    }
];
